import React from 'react';

import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock';

import './CommitteeDetail.scss';

const CommitteeDetail = ({ title, description, composeTxt }) => {
  return (
    <div className="committee_detail_component">
      <div className="cross_wrapper_extreme_right">
        <div className="col_special">
          <div className="col_info">
            <TitleBlock
              headingTag="h3"
              title={title}
              color="color_dark_bleu"
            />
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </div>
          <div className="col_bleu_box" dangerouslySetInnerHTML={{ __html: composeTxt }}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommitteeDetail;
