import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import { Button } from '../../../../Molecules/Button/Button';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import CommitteeDetail from '../../../../components/ContentType/CommitteeDetail/CommitteeDetail';
import DocumentView from '../../../../Molecules/DocumentView/DocumentView';
import {
  formatBytes,
  getBreadCrumb,
  removeHtmlTag,
} from '../../../../Utils/Utils';
import IntroPage from '../../../../components/ContentType/IntroPage/IntroPage';
import Layout from '../../../../Layout';
import ListDirectionMember from '../../../../components/ContentType/ListDirectionMember/ListDirectionMember';
import ListAdminMembers from '../../../../components/ListAdminMembers/ListAdminMembers';
import PageBanner from '../../../../components/PageBanner/PageBanner';
import PageBannerStyle from '../../../../components/PageBannerStyle/PageBannerStyle';
import QuoteMark from '../../../../Atoms/QuoteMark/QuoteMark';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitleBlock from '../../../../Molecules/TitleBlock/TitleBlock';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import useMedias from '../../../../hooks/useMedias';
import { trackCtaGeneral } from '../../../../Utils/Tracking';

import './styles.scss';
let classNames = require('classnames');

const GovernancePage = ({ data }) => {
  const intl = useIntl();
  const { getImage, getDocument } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const documentsArray = data.allDocuments.edges;
  const governanceData = data?.governanceData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, governanceData.path?.alias);

  const [innerNavList, setInnerNavList] = useState([]);
  const [isBlockBannerAvail, setIsBlockBannerAvail] = useState(false);
  const [allText, setAllText] = useState('');
  const metaTags = governanceData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });

  let globalText = '';
  let list_board_of_directors = [];
  let sectionArr = [];
  let sectionCount = 0;
  let isBanner = false;


  useEffect(() => {
    setInnerNavList(sectionArr);
    setIsBlockBannerAvail(isBanner);
    setAllText(
      removeHtmlTag(
        `${governanceData.title}.${governanceData.field_sous_titre !== null &&
          governanceData.field_sous_titre !== undefined
          ? governanceData.field_sous_titre
          : ''
        }.${governanceData.body?.processed != undefined &&
          governanceData.body?.processed != null
          ? governanceData.body?.processed
          : ''
        }.${globalText}`
      )
    );
  }, []);

  return (
    <Layout>
      <Seo
        title={governanceData?.field_metatag?.title ? governanceData?.field_metatag?.title : metaTitle}
        description={governanceData?.field_metatag?.description ? governanceData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'page_gouvernance')}>
        <ScrollPageNav data={innerNavList} />
        {!isBlockBannerAvail && (
          <PageBanner
            visuel={getImage(
              imagesArray,
              governanceData?.relationships?.field_image?.drupal_internal__mid
            )}
            alt={governanceData.relationships?.field_image?.field_media_image?.alt || ''}
            scroll={true}
          >
            <div className="wrapper_page">
              <Breadcrumb
                page_banner={true}
                data={{
                  //gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
                  parentPage: {
                    title: breadCrumb?.parent?.name,
                    url: breadCrumb?.parent?.link,
                  },
                  currentPage: {
                    title: breadCrumb?.current?.name,
                    url: breadCrumb?.current?.link,
                  },
                  locale: governanceData.langcode,
                }}
              />

              <div
                className={classNames({
                  title_audio:
                    allText &&
                    governanceData.field_version_audio &&
                    allText.length > 30,
                })}
              >
                <TitlePage
                  color="color_white"
                  title={governanceData?.title}
                  description={governanceData?.body?.processed}
                />
                {allText &&
                  governanceData.field_version_audio &&
                  allText.length > 30 && (
                    <AudioComponent
                      text={intl.formatMessage({
                        id: 'detailsActivities.button.listen.label',
                      })}
                      textToRead={allText}
                      color="color_white"
                    />
                  )}
              </div>
            </div>
          </PageBanner>
        )}

        {governanceData?.relationships?.field_blocs?.map((block, i) => {
          globalText += `${block.field_title?.processed != undefined &&
            block.field_title?.processed != null
            ? block.field_title?.processed
            : ''
            }.${block.field_subtitle?.processed != undefined &&
              block.field_subtitle?.processed != null
              ? block.field_subtitle?.processed
              : ''
            }.${block.body?.processed != undefined && block.body?.processed != null
              ? block.body?.processed
              : ''
            }`;
          switch (block.__typename) {
            case 'block_content__banner':
              isBanner = true;

              return (
                <PageBannerStyle
                  key={i}
                  visuel={getImage(
                    imagesArray,
                    block?.relationships?.field_image?.drupal_internal__mid
                  )}
                  alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                  scroll={true}
                  breadcrumbData={{
                    gparent: {
                      title: breadCrumb?.grandParent?.name,
                      url: breadCrumb?.grandParent?.link,
                    },
                    parentPage: {
                      title: breadCrumb?.parent?.name,
                      url: breadCrumb?.parent?.link,
                    },
                    currentPage: {
                      title: breadCrumb?.current?.name,
                      url: breadCrumb?.current?.link,
                    },
                    locale: governanceData.langcode,
                  }}
                >
                  <div className="wrapper_page">
                    <TitlePage
                      color="color_white"
                      title={governanceData?.title}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: block.body?.processed,
                      }}
                    />
                    {allText &&
                      governanceData.field_version_audio &&
                      allText.length > 30 && (
                        <AudioComponent
                          text={intl.formatMessage({
                            id: 'detailsActivities.button.listen.label',
                          })}
                          textToRead={allText}
                        />
                      )}
                  </div>
                </PageBannerStyle>
              );

            case 'block_content__block_texte':
              sectionCount++;
              sectionArr.push({
                title: block.field_title?.processed,
                section: 'scroll_to_' + sectionCount,
              });
              return (
                <IntroPage
                  key={i}
                  customClass={'topZIndex ' + 'scroll_to_' + sectionCount}
                  title={block.field_title?.processed}
                  subtitle={block.field_subtitle?.processed}
                  size="border" //wrapper
                  text={block.body?.processed}
                  visuel={getImage(
                    imagesArray,
                    block?.relationships?.field_image?.drupal_internal__mid
                  )}
                  alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                />
              );

            case 'block_content__block_conseil_administration':
              block.relationships?.field_biographies?.forEach((biography) => {
                globalText += `${biography.title}.${biography.field_poste}.`;
                list_board_of_directors.push({
                  visuel: getImage(
                    imagesArray,
                    biography?.relationships?.field_image?.drupal_internal__mid
                  ),
                  alt: biography.relationships?.field_image?.field_media_image?.alt || '',
                  fullname: biography.title,
                  post: biography.field_poste,
                  link: '/' + biography.path?.langcode + biography.path?.alias,
                });
              });
              return (
                <section
                  className={classNames(
                    'section_content',
                    'section_board_of_directors'
                  )}
                  key={i}
                >
                  <div className="wrapper_page_xs">
                    <TitleSection
                      title={block.field_title?.processed}
                      description={block.field_subtitle?.processed}
                    />
                  </div>
                  <div className="wrapper_page">
                    <ListAdminMembers listAdmins={list_board_of_directors} />
                  </div>
                </section>
              );

            case 'block_content__block_citation':
              globalText += block.field_citation;
              return (
                <section
                  className={classNames(
                    'section_content',
                    'section_board_of_directors'
                  )}
                  key={i}
                >
                  <div className="quote_container">
                    <QuoteMark
                      lang={block.langcode}
                      text={block.field_citation}
                    />
                  </div>
                </section>
              );

            case 'block_content__block_document':
              let docArr = [];
              block.relationships?.field_file?.forEach((file) => {
                docArr.push({
                  name: getDocument(documentsArray, file?.drupal_internal__mid)
                    ?.description,
                  size: `(.pdf ${formatBytes(
                    getDocument(documentsArray, file?.drupal_internal__mid)
                      ?.filesize
                  )})`,
                  link: getDocument(documentsArray, file?.drupal_internal__mid)
                    ?.uri.url,
                });
              });
              return (
                <section className={classNames('section_content')} key={i}>
                  <div className="wrapper_page">
                    <DocumentView data={docArr} currpage="page_gouvernance" />
                  </div>
                </section>
              );

            case 'block_content__block_gouvernance':
              return (
                <section
                  className={classNames(
                    'section_content',
                    'section_list_committee'
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top="left"
                    color="catskill_white"
                    bottomInfinite={true}
                  >
                    <div className="wrapper_page">
                      {block.relationships?.field_blocs?.map((compose, l) => {
                        globalText += `${compose.field_title?.processed != undefined &&
                          compose.field_title?.processed != null
                          ? compose.field_title?.processed
                          : ''
                          }.${compose.field_bloc_text_1.processed != undefined &&
                            compose.field_bloc_text_1.processed != null
                            ? compose.field_bloc_text_1.processed
                            : ''
                          }.${compose.field_bloc_text_2.processed != undefined &&
                            compose.field_bloc_text_2.processed != null
                            ? compose.field_bloc_text_2.processed
                            : ''
                          }`;
                        return (
                          <CommitteeDetail
                            key={l}
                            title={compose.field_title?.processed}
                            description={compose.field_bloc_text_1.processed}
                            composeTxt={compose.field_bloc_text_2.processed}
                          />
                        );
                      })}
                    </div>
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_direction':
              let directionMembers = [];
              let detailTxt = '';
              block.relationships?.field_biographies?.forEach((biography) => {
                detailTxt =
                  biography.body?.summary != '' &&
                    biography.body?.summary != undefined
                    ? '<p>' + biography.body?.summary + '</p>'
                    : biography.body?.processed;
                globalText += `${biography.title}.${biography.field_poste}.${biography.field_date_de_naissance}.${detailTxt}.`;
                directionMembers.push({
                  visuel: getImage(
                    imagesArray,
                    biography?.relationships?.field_image?.drupal_internal__mid
                  ),
                  alt: biography.relationships?.field_image?.field_media_image?.alt || '',
                  name: biography.title,
                  post: biography.field_poste,
                  dob: biography.field_date_de_naissance,
                  textDetails: detailTxt,
                });
              });
              sectionCount++;
              sectionArr.push({
                title: block.field_title?.processed,
                section: 'scroll_to_' + sectionCount,
              });
              return (
                <section
                  className={classNames(
                    'section_content',
                    'section_direction',
                    'scroll_to_' + sectionCount
                  )}
                  key={i}
                >
                  <ShapeBackground top="left" bottom="left" color="dark_bleu">
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        h2color="white"
                      />
                    </div>
                    <div className="wrapper_page">
                      <div className="btn_container">
                        {block.field_lien_multiple?.map((button, j) => {
                          return (
                            <Button
                              key={j}
                              label={button.title}
                              link={button.url}
                              target={
                                button.url.indexOf('.pdf') > 0
                                  ? '_blank'
                                  : '_self'
                              }
                              onClickEvent={() => trackCtaGeneral('clic_cta', 'la_gouvernance', 'groupe', 'accueil', button.title, 'cta')}
                            />
                          );
                        })}
                      </div>
                    </div>
                    <ListDirectionMember
                      listDirectionMember={directionMembers}
                      textColor="color_white"
                    />
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_document_avec_titre':
              let allDocDetails = [];

              block.relationships?.field_document_block?.forEach((doc) => {
                let filesArr = [];
                doc.relationships?.field_file?.forEach((file) => {
                  filesArr.push({
                    name: getDocument(
                      documentsArray,
                      file?.drupal_internal__mid
                    )?.description,
                    size: `(.pdf ${formatBytes(
                      getDocument(documentsArray, file?.drupal_internal__mid)
                        ?.filesize
                    )})`,
                    link: getDocument(
                      documentsArray,
                      file?.drupal_internal__mid
                    )?.uri.url,
                  });
                });

                allDocDetails.push({
                  title: doc.field_title?.processed,
                  subTitle: doc.field_subtitle?.processed,
                  linkTitle: doc.field_lien?.title,
                  link: doc.field_lien?.url,
                  files: filesArr,
                });
              });
              return (
                <section
                  className={classNames('section_content', 'section_documents')}
                  key={i}
                >
                  <div className="wrapper_page_xs">
                    <TitleSection title={block.field_title?.processed} />
                    {allDocDetails.map((document, k) => {
                      sectionCount++;
                      sectionArr.push({
                        title: document.title,
                        section: 'scroll_to_' + sectionCount,
                      });
                      return (
                        <div
                          className={classNames(
                            'document_type',
                            'scroll_to_' + sectionCount
                          )}
                          key={k}
                        >
                          <TitleBlock title={document.title} />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: document.subTitle,
                            }}
                          ></div>
                          {document.linkTitle && (
                            <Button
                              label={document.linkTitle}
                              link={document.link}
                              target={
                                document.link?.indexOf('.pdf') > 0
                                  ? '_blank'
                                  : '_self'
                              }
                            />
                          )}
                          <DocumentView data={document.files} currpage="page_gouvernance" />
                        </div>
                      );
                    })}
                  </div>
                </section>
              );

            default:
              break;
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query governanceTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
  ) {
    governanceData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          field_type
          field_version_audio
          path {
            langcode
            alias
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__banner {
                  body {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_texte {
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_conseil_administration {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  relationships {
                    field_biographies {
                      body {
                        processed
                      }
                      field_poste
                      field_date_de_naissance
                      title
                      path {
                        langcode
                        alias
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_citation {
                  field_citation
                  langcode
                }
                ... on block_content__block_document {
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_gouvernance {
                  relationships {
                    field_blocs {
                      field_bloc_text_1 {
                        processed
                      }
                      field_bloc_text_2 {
                        processed
                      }
                      field_title {
                        processed
                      }
                    }
                  }
                }
                ... on block_content__block_direction {
                  field_lien_multiple {
                    url
                    title
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_biographies {
                      body {
                        processed
                        summary
                      }
                      field_poste
                      field_date_de_naissance
                      title
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_document_avec_titre {
                  field_title {
                    processed
                  }
                  relationships {
                    field_document_block {
                      field_title {
                        processed
                      }
                      field_subtitle {
                        processed
                      }
                      field_lien {
                        title
                        url
                      }
                      relationships {
                        field_file {
                          drupal_internal__mid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          body {
            processed
          }
        }
      }
    }

    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_profile_large
                gatsby_profile_thumbnail
              }
            }
          }
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default GovernancePage;
