import * as React from 'react';

import './ListAdminMembers.scss';
import MemberProfileBlock from '../../Molecules/MemberProfileBlock/MemberProfileBlock';
import SliderComponent from '../SliderComponent/SliderComponent';

const ListAdminMembers = ({ listAdmins }) => {
  const slider_settings = {
    className: 'arrow_bottom_slider arrow_after_text',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1, //no slider on desktop
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        dots: false
      }
    }]
  };

  return (
    <>
      <div className="list_admin_member_component">
        <div className="only_tablet_desktop">
          <div className="list_admin_member">
            {
              listAdmins.map((member, index) => (
                <MemberProfileBlock
                  visuel={member.visuel}
                  alt={member.alt}
                  fullname={member.fullname}
                  post={member.post}
                  link={member.link}
                  key={index}
                />
              ))
            }
          </div>
        </div>
        <div className="only_mobile">
          <div className="list_admin_member">
            <SliderComponent settings={slider_settings}>
              {
                listAdmins.map((memberMob, i) => (
                  <MemberProfileBlock
                    visuel={memberMob.visuel}
                    alt={memberMob.alt}
                    fullname={memberMob.fullname}
                    post={memberMob.post}
                    link={memberMob.link}
                    key={i}
                  />
                ))
              }
            </SliderComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListAdminMembers;
