import React from 'react';

import PropTypes from 'prop-types';

import DirectionBlock from '../DirectionBlock/DirectionBlock';
import SliderComponent from '../../SliderComponent/SliderComponent';

const ListDirectionMember = ({
  listDirectionMember,
  textColor
}) => {
  const slider_settings = {
    className: 'directors_profile_height',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 300,
    initialSlide: 0,
    arrows: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className="list_direction_member">
      <div className="wrapper_page_xs only_tablet_desktop">
        {
          listDirectionMember.map((member, index) => (
            <DirectionBlock
              key={index}
              extremeRight={(index % 2 == 0) ? true : false}
              extremeLeft={(index % 2 == 0) ? false : true}
              visuel={member.visuel}
              alt={member.alt}
              name={member.name}
              post={member.post}
              dob={member.dob}
              textDetails={member.textDetails}
              textColor={textColor}
            />
          ))
        }
      </div>
      <div className="only_mobile">
        <SliderComponent settings={slider_settings} arrowColor={textColor}>
          {
            listDirectionMember.map((member, index) => (
              <DirectionBlock
                key={index}
                mobile={true}
                visuel={member.visuel}
                alt={member.alt}
                name={member.name}
                post={member.post}
                dob={member.dob}
                textDetails={member.textDetails}
                textColor={textColor}
              />
            ))
          }
        </SliderComponent>
      </div>
    </div>
  );
};

ListDirectionMember.defaultProps = {
  text: 'Default Description Description Description Description Description Description Description',
  alt: '',
};

ListDirectionMember.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  extremeRight: PropTypes.bool,
  extremeLeft: PropTypes.bool
};

export default ListDirectionMember;
